import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./custom.css"
import { SignForm } from "../components/SignForm"
import { Grid } from "semantic-ui-react"
import { SignatureList } from "../components/SignatureList"
import { TopHeader } from "../components/TopHeader"
import { rhythm } from "../utils/typography"

class Faq extends React.Component {
  constructor() {
    super()
    this.faq = this.faq.bind(this)
  }

  allPosts() {
    const { data } = this.props
    return data.allMarkdownRemark.edges
  }

  faq() {
    return this.allPosts().find(
      post => post.node.frontmatter.description === "faq"
    ).node.html
  }

  render() {
    const siteTitle = this.props.data.site.siteMetadata.title

    return (
      <>
        <TopHeader />
        <Layout location={this.props.location} title={siteTitle}>
          <div
            style={{
              maxWidth: rhythm(30),
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <h1 className="main-title">Declaration FAQs</h1>
            <SEO title="FAQ" />
            <section dangerouslySetInnerHTML={{ __html: this.faq() }}></section>
          </div>
        </Layout>
      </>
    )
  }
}

export default Faq

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          html
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
